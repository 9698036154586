import React, { useEffect, useState } from 'react';
import './Features.css';
import CardWithImage from './CardWithImage';

import dots from '../Images/dots.svg'
import heartbeat from '../Images/Heartbeat.png'
import security from '../Images/Security.png'
import outage from '../Images/Outage.png'
import MFA from '../Images/MFA.png'

const Features = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollPosition(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const calculateStyle = (index) => {
    const offset = 30 * (index + 2); // Adjust this multiplier to control the animation speed
    const opacity = Math.min(scrollPosition / (offset + 200), 1);
    return { opacity };
  };

  return (
    <div className="container">
      <section className="features">
        <h1 id="features">External Monitoring for File Transfer Servers</h1>
        <p>Oversee your service remotely from global regions,<br/>
        tracking service, outage, and bottlenecks.</p>
        <article>
          <CardWithImage title="Heartbeat Testing" subtitle="REPORTING" img={heartbeat} imgAlt="Heartbeat testing">
            <p>Test, measure, and analyse SFTP services from servers around the world, producing a comprehensive overview of system performance.</p>
            <p>Global remote heartbeats to SFTP servers allow for isolation of bottlenecks, tracking performance over time, and internal vs external slowdown reports.</p>
            <p>Gain insight into system performance, and be notified of system outage or slowdown before users are affected.</p>
          </CardWithImage>
        </article>

        <article>
          <CardWithImage title="Multi-factor Authentication" subtitle="SECURITY" img={MFA} imgAlt="Multi-factor Authentication">
            <p>Utilise multi-factor authentication (MFA) to enhance the detail of monitoring, ensuring robust access control and system integrity.</p>
            <p>Monitor file transfer activities with MFA-enabled heartbeats to detect and isolate performance issues, track transfer speeds, and identify potential bottlenecks.</p>
            <p>Receive real-time alerts and detailed reports on system security, helping to prevent slowdowns or outages before they affect users, while maintaining strong security through MFA.</p>
          </CardWithImage>
        </article>

        <article>
          <CardWithImage title="Synthetic Usage and Security" subtitle="SECURITY" img={security} imgAlt="Synthetic usage and security">
            <p>Synthetic user testing attempts to ignore parameters and find weaknesses in systems that real-world users may be slow to detect.</p>
            <p>By simulating usage without correct credentials, improper key handling, and out-of-order responses, systems can be tested rapidly and comprehensively.</p>
            <p>Regular SSH security checks ensure that best practices and up-to-date security measures are used across a service.</p>
          </CardWithImage>
        </article>

        <article>
          <CardWithImage title="Outage Detection and Reporting" subtitle="REPORTING" img={outage} imgAlt="Outage detection and reporting">
            <p>Detect outages and slowdowns externally, and be notified before real-world users are affected. Protect against loss of critical service.</p>
            <p>System performance is continually measured against historical data, and markers set for notification of performance degradation and outage.</p>
            <p>Gain an understanding of outages through reporting. Analyse when performance degradation begins and ends, with insight into the cause.</p>
          </CardWithImage>
        </article>
      </section>
    </div>
  );
};

export default Features;
