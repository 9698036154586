import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './Articles.module.css';
import filepart1 from './ArticleImages/FilePart 1.png';
import filepart2 from './ArticleImages/FilePart 2.png';

const Article = ({ image, title, overview, link }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Link to={link} className={classes.articleLink}>
            <div className={classes.articleCard}>
                <h3>{title}</h3>
                <p>{overview}</p>
            </div>
        </Link>
    );
};

const OverviewGrid = ({ articles, sectionTitle }) => (
    <div className={classes.section}>
        <h1>{sectionTitle}</h1>
        <div className={classes.grid}>
            {articles.map((article, index) => (
                <Article
                    key={index}
                    title={article.title}
                    overview={article.overview}
                    link={article.link}
                />
            ))}
        </div>
    </div>
);

const ArticleOverview = () => {
    const articles = [
        {
            title: 'The .filepart Challenge',
            overview: 'An overview of the .filepart use case in Managed File Transfer and its complications.',
            link: '/articles/filepart'
        },
        {
            title: 'Why sFTP is the Best Choice',
            overview: 'sFTP has emerged as the superior protocol due to its robust security features, ease of use, and compliance with modern standards.',
            link: '/articles/why-sftp'
        },
        // Add more articles here
    ];

    const documentation = [
        {
            title: 'Use Cases',
            overview: 'An overview of the different uses cases for Threpoly',
            link: '/documentation/use-cases'
        },
        {
            title: 'Return on Investment',
            overview: 'An overview of the ways Threpoly can generate a return on investment.',
            link: '/documentation/return-on-investment'
        },
        {
            title: 'Benefits for Channel Partners',
            overview: 'Partnering with Threpoly is a strategic move that promises long-term growth and sustained success.',
            link: '/documentation/benefits-for-channel-partners'
        },
        // Add more documentation as needed
    ];

    return (
        <div className={classes.heroBlogWrapper}>
            <div className={classes.heroBlog}>
                <div className={classes.heroBlog}>
                    <div className={classes.heroInner}>
                        <h4>&nbsp;</h4>
                        <h1>Documentation</h1>
                    </div>
                </div>
            </div>
            <div className='container'>
                <OverviewGrid articles={articles} sectionTitle="Articles" />
                <OverviewGrid articles={documentation} sectionTitle="Documentation" />
            </div>
        </div>
    );
};

export default ArticleOverview;
