import React from 'react';
import './CardWithImage.css';


const CardWithImage = ({ title, subtitle, children, img, imgAlt }) => {
  return (
    <div className="cardWithImage-container">
      <img src={img} className='cardWithImage-placeholder' alt={imgAlt} />
      <div className="cardWithImage-content">
        <p className="cardWithImage-subtitle">{subtitle}</p>
        <h2 className="cardWithImage-title">{title}</h2>
        <div className="cardWithImage-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardWithImage;
