// MobileNav.js
import React from 'react';
import './MobileNav.css';
import logo from '../threpoly-logo-dark.svg';

const MobileNav = ({ toggleMenu }) => {
  return (
    <div className="mobile-nav">
    <div className='burger-wrapper'>
      <button className="burger-menu-mobile" onClick={toggleMenu}>
        ☰
      </button>
      </div>
      <a href="/"><img src={logo} className="logo-mobile-menu" alt="Threpoly Logo Dark" /></a>
      <div className='nav-items'>
        <a href="#contact" onClick={toggleMenu}>Free demo</a>
        <a href="#features" onClick={toggleMenu}>Features</a>
        <a href="/articles">Documentation</a>
        <a href="#faqs" onClick={toggleMenu}>Contact</a>
      </div>
    </div>
  );
};

export default MobileNav;
