import React, { useEffect, useState } from 'react';
import './Hero.css';
import capture from '../Images/threpoly-capture.png'

const Hero = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollPosition(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const calculateStyle = (index) => {
    const offset = 30 * (index + 2); // Adjust this multiplier to control the animation speed
    const opacity = Math.max(1 - scrollPosition / (offset + 200), 0);
    return { opacity };
  };

  return (
    <div className='image-background'>
    <div className="container">
    <section className="hero" style={calculateStyle(2)}>
      <h1>Global file transfer monitoring</h1>
      <p>Remote managed file transfer server monitoring.
      <br/>Enriched with heartbeat monitoring and service status notifications.</p>
      <a href="#features"><button>Learn more</button></a>
      <img className="screenshot" src={capture} alt="Threpoly SFTP Monitor screenshot" style={calculateStyle(2)}/>
    </section>
    </div>
    </div>
  );
};

export default Hero;
