import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import classes from './Articles.module.css';
import HeaderBlog from "../Components/HeaderBlog";
import filepart1 from './ArticleImages/FilePart 1.png';
import filepart2 from './ArticleImages/FilePart 2.png';

const Filepart = () => {
    const sections = [
        { id: 'introduction', title: 'Introduction' },
        { id: 'temporary-file-name', title: 'What is a Temporary File Name?' },
        { id: 'temporary-file-transfer', title: 'Why would a File be Transferred with a Temporary File Name?' },
        { id: 'perfect-solution-problem', title: 'This sounds like the Perfect Solution - What is the Problem?' },
        { id: 'event-driven-architecture', title: 'Event Driven Architecture' },
        { id: 'complications-temp-file', title: 'Complications with Temporary File Names' },
        { id: 'number-temp-file-formats', title: 'A Large Number of Temporary File Name Formats' },
        { id: 'dealing-temp-file', title: 'How do MFT’s deal with Temporary File Extensions Today?' },
        { id: 'best-solutions', title: 'The Best Solutions I have Found to Date' },
        { id: 'step1-ignore-policy', title: 'Step 1: Define a New File Event Trigger with an Ignore Policy' },
        { id: 'step2-rename-event', title: 'Step 2: Define a Rename Event Trigger' },
        { id: 'example-workflow', title: 'Example Workflow' },
        { id: 'temp-location-upload', title: 'Uploading the File to a Temporary Location' },
        { id: 'summary', title: 'Summary' },
    ];

    useEffect(() => {
        const handleScroll = (event) => {
            event.preventDefault();
            const id = event.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(id);
            const offset = window.innerHeight / 2 - targetElement.getBoundingClientRect().height / 2;
            window.scrollTo({
                top: targetElement.offsetTop - offset,
                behavior: 'smooth'
            });
        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => link.addEventListener('click', handleScroll));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleScroll));
        };
    }, []);

    return (
        <>
            <HeaderBlog />
            <div className={classes.heroBlogWrapper}>
                <div className={classes.heroBlog}>
                    <div className={classes.heroInner}>
                        <h4>Article</h4>
                        <h1>The .filepart Challenge</h1>
                        <Link to='/articles'><p className={classes.backLink}>Back to documentation</p></Link>
                    </div>
                </div>
                <div className='container'>
                    <div className={classes.articleWrapper}>
                        <div className={classes.articleInner}>

                            <div id="introduction">
                                <h2>Introduction</h2>
                                <p>
                                    Many of today's problems are often caused by solutions to old challenges, and the
                                    '.filepart' use case is no exception. While one could argue that temporary file
                                    names are no longer required in today's MFT landscape, it remains a very common use
                                    case that businesses encounter.
                                </p>
                            </div>

                            <div id="temporary-file-name">
                                <h2>What is a Temporary File Name?</h2>
                                <p>
                                    A temporary file name is usually a combination of a file name with an appended
                                    value. The appended value can vary, but common examples include: .filepart, .part,
                                    and .temp. The purpose of a temporary file name is to indicate that a file is not
                                    complete.
                                </p>
                            </div>

                            <img src={filepart1} alt='Filepart solution diagram'/>

                            <div id="temporary-file-transfer">
                                <h2>Why would a File be Transferred with a Temporary File Name?</h2>
                                <p>
                                    Before the invention of Managed File Transfer (MFT), determining if a file transfer
                                    had completed successfully was a significant challenge. If a file transfer failed,
                                    it was common for the partially uploaded file to remain available on the server.
                                    This could lead to various issues for the business responsible for processing the
                                    file, such as dealing with corrupted files or files missing key information.
                                    <ul>
                                        <li>Perform the file transfer using a temporary file name, for example:
                                            myFile.txt.filepart.
                                        </li>
                                        <li>Once the transfer has completed successfully, rename the file back to its
                                            original name: myFile.txt.
                                        </li>
                                    </ul>
                                    This approach ensured that businesses knew to ignore any “.filepart” files and only
                                    process files that had been transferred correctly.
                                </p>
                            </div>

                            <div id="perfect-solution-problem">
                                <h2>This sounds like the Perfect Solution - What is the Problem?</h2>
                                <p>
                                    The reason we still see temporary file names and extensions today is because it was
                                    truly an effective solution for a sender to indicate to the receiver that a file
                                    transfer had completed successfully and was ready to be processed. However, with the
                                    introduction of Managed File Transfer (MFT), complications began to arise.
                                </p>
                            </div>

                            <div id="event-driven-architecture">
                                <h2>Event Driven Architecture</h2>
                                <p>
                                    One of the best features of MFT is its event-driven architecture, which allows
                                    automation to be triggered after any kind of file transfer event. A common example
                                    of this is a ‘new file event.’ When a file is uploaded to an MFT server, automation
                                    can be initiated to process the uploaded file.
                                </p>
                            </div>

                            <div id="complications-temp-file">
                                <h2>Complications with Temporary File Names</h2>
                                <p>
                                    When a file is uploaded with a temporary file name, a typical sequence of events
                                    occurs:
                                    <ul>
                                        <li>The file is uploaded with a temporary name, such as myFile.txt.filepart.
                                        </li>
                                        <li>The new file event automation is triggered to process the uploaded file.
                                        </li>
                                        <li>During the automation, the file is renamed (the sender removes the temporary
                                            extension, i.e. .filepart).
                                        </li>
                                    </ul>
                                    This renaming process can often cause the automation to break, leading to errors
                                    such as: “Unexpected exception storing the file.” The conflict between the
                                    automation process and the renaming of the file results in these complications.
                                </p>
                            </div>

                            <div id="number-temp-file-formats">
                                <h2>A Large Number of Temporary File Name Formats</h2>
                                <p>
                                    While common temporary file name formats like file_name.filepart exist, in reality,
                                    any temporary file name can be used. In fact, senders have been known to generate a
                                    unique extension for every file transfer! This variability means that even if a
                                    business’s MFT system could handle a temporary file extension, it becomes very
                                    difficult to support every possible combination. The diversity in temporary file
                                    naming conventions complicates the ability of MFT systems to consistently and
                                    accurately process these files.
                                </p>
                            </div>

                            <div id="dealing-temp-file">
                                <h2>How do MFT’s deal with Temporary File Extensions Today?</h2>
                                <p>
                                    Amazingly, even with all the great enhancements that MFTs have introduced, handling
                                    temporary file names is still a challenge. However, there are a few solutions out
                                    there:
                                    <ul>
                                        <li>Ask the sender nicely to stop using temporary file names</li>
                                        <li>Introduce file polling of your own MFT server</li>
                                    </ul>
                                </p>
                            </div>

                            <div id="best-solutions">
                                <h2>The Best Solutions I have Found to Date</h2>
                                <p>
                                    I have faced this issue many times during my MFT career and have only found a
                                    limited number of solutions that follow best practices of MFT without introducing
                                    unwanted overhead or file polling.
                                </p>
                            </div>

                            <div id="step1-ignore-policy">
                                <h2>Step 1: Define a New File Event Trigger with an Ignore Policy</h2>
                                <p>
                                    Most MFT solutions provide the ability to define an ignore policy during the event
                                    configuration. When creating your New File Event trigger, configure it to ignore any
                                    known temporary file extensions (e.g., .filepart, .part). This means any file
                                    uploaded without a temporary extension will be processed as expected—off to a good
                                    start!
                                </p>
                            </div>

                            <div id="step2-rename-event">
                                <h2>Step 2: Define a Rename Event Trigger</h2>
                                <p>
                                    It should also be possible to configure a file transfer rename event. This means
                                    that when a file is renamed, we can trigger some kind of automation. Configure the
                                    event to trigger the same automation as the new file event, applying the same ignore
                                    policy.
                                </p>
                            </div>

                            <div id="example-workflow">
                                <h2>Example Workflow</h2>
                                <p>
                                    Let's look at an example of how this would work:
                                    <ul>
                                        <li>The sender uploads test_file_01.txt.filepart to your MFT server.</li>
                                        <li>No new file transfer event is triggered, as it meets the ignore policy.</li>
                                        <li>The sender renames the file to remove the extension.</li>
                                        <li>The rename trigger event kicks in as it now meets the trigger conditions.
                                        </li>
                                        <li>The file is processed by the automated workflow or scripting utility.</li>
                                    </ul>
                                </p>
                            </div>

                            <div id="temp-location-upload">
                                <h2>Uploading the File to a Temporary Location</h2>
                                <p>
                                    Though this solution is not possible for all types of clients performing a file
                                    transfer, it works very effectively when implemented. The idea is to create a
                                    temporary directory without any New File Event triggers defined on it. This folder
                                    will be used for the sender to upload their file. In this scenario, they can use any
                                    temporary file name they like.
                                </p>
                                <p>
                                    <strong>Steps to Implement:</strong>
                                    <ul>
                                        <li>Set up a temporary directory that does not have any New File Event triggers
                                            defined on it.
                                        </li>
                                        <li>The sender uploads their file to this temporary directory. They can use any
                                            temporary file name, such as myFile.txt.filepart.
                                        </li>
                                        <li>Once the upload is complete and the client attempts to rename the file to
                                            remove the temporary extension, they can also move the file to the target
                                            folder.
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <img src={filepart2} alt='Filepart solution diagram'/>

                            <div id="summary">
                                <h2>Summary</h2>
                                <p>
                                    Temporary file names are here to stay in the world of Managed File Transfer. Even
                                    though MFT applications often do not provide out-of-the-box support for them, there
                                    are a few implementations available that should allow this old but tricky use case
                                    to work effectively.
                                </p>
                            </div>

                        </div>
                        <div>
                            <div className={classes.sectionsOverview}>
                            <h2>Overview</h2>
                                <ul>
                                    {sections.map(section => (
                                        <li key={section.id}>
                                            <a href={`#${section.id}`}>{section.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filepart;
