import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import classes from './Articles.module.css';
import HeaderBlog from "../Components/HeaderBlog";

const UseCases = () => {
    const sections = [
        { id: 'introduction', title: 'Introduction' },
        { id: 'availability-performance', title: 'Ensuring Cloud MFT Instance Availability and Performance: Monitor Cloud MFT Providers' },
        { id: 'migration', title: 'Facilitating MFT Migration from On-Premises to Cloud' },
        { id: 'performance-monitoring', title: 'Optimizing MFT System Performance Performance Monitoring' },
        { id: 'security-compliance', title: 'Enhancing Security and Compliance Security Monitoring' },
        { id: 'synthetic-user-monitoring', title: 'Synthetic User Monitoring' },
        { id: 'issue-resolution', title: 'Proactive Issue Resolution' },
        { id: 'operational-efficiency', title: 'Improving Operational Efficiency' },
        { id: 'vendor-monitoring', title: 'Vendor Service Level Monitoring' },
        { id: 'partner-collaboration', title: 'Business Partner Collaboration' },
    ];

    useEffect(() => {
        const handleScroll = (event) => {
            event.preventDefault();
            const id = event.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(id);
            const offset = window.innerHeight / 2 - targetElement.getBoundingClientRect().height / 2;
            window.scrollTo({
                top: targetElement.offsetTop - offset,
                behavior: 'smooth'
            });
        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => link.addEventListener('click', handleScroll));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleScroll));
        };
    }, []);

    return (
        <>
            <HeaderBlog />
            <div className={classes.heroBlogWrapper}>
                <div className={classes.heroBlog}>
                    <div className={classes.heroInner}>
                        <h4>Documentation</h4>
                        <h1>Threpoly Use Cases</h1>
                        <Link to='/articles'><p className={classes.backLink}>Back to documentation</p></Link>
                    </div>
                </div>
                <div className='container'>
                    <div className={classes.articleWrapper}>
                        <div className={classes.articleInner}>
                            <div id="introduction">
                                <h2>Introduction</h2>
                                <p>
                                    Managed File Transfer (MFT) solutions are crucial for securely exchanging data across networks,
                                    especially in industries with stringent data security and compliance requirements.
                                    To ensure optimal performance, security, and reliability, monitoring is essential.
                                    <br /><br />
                                    Threpoly is the only SaaS solution tailored specifically for MFT systems,
                                    providing advanced monitoring from sources around the globe,
                                    essential to ensure optimal performance, security, and reliability.
                                    <br /><br />
                                    Below is an overview of the different use cases for Threpoly.
                                </p>
                            </div>

                            <div id="availability-performance">
                                <h2>Ensuring Cloud MFT Instance Availability and Performance: Monitor Cloud MFT Providers</h2>
                                <p>
                                    Track the health and performance of your cloud-based MFT solutions, ensuring they meet SLA commitments.
                                    This helps hold your providers accountable and ensures you receive the agreed level of service.
                                </p>
                            </div>

                            <div id="migration">
                                <h2>Facilitating MFT Migration from On-Premises to Cloud</h2>
                                <p>
                                    <ul>
                                        <li>
                                            Pre-Migration Analysis: Assess the current on-premises environment to understand the baseline
                                            performance and identify potential bottlenecks that need addressing before migration.
                                        </li>
                                        <li>
                                            Migration Support: Provide insights and performance metrics during the transition from on-premises
                                            to cloud-based MFT solutions. Identify and resolve issues quickly to ensure a smooth migration.
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div id="performance-monitoring">
                                <h2>Optimizing MFT System Performance Performance Monitoring</h2>
                                <p>
                                    Continuously monitor the performance of MFT systems to ensure they operate at peak efficiency. Identify
                                    and resolve performance degradation proactively.
                                </p>
                            </div>

                            <div id="security-compliance">
                                <h2>Enhancing Security and Compliance Security Monitoring</h2>
                                <p>
                                    Detect and respond to security incidents in real-time by monitoring for unusual activity or potential
                                    breaches within the MFT environment to meet internal security and regulatory requirements.
                                </p>
                            </div>

                            <div id="synthetic-user-monitoring">
                                <h2>Synthetic User Monitoring</h2>
                                <p>
                                    <ul>
                                        <li>
                                            Simulate User Interactions: Use synthetic transactions to mimic user behavior and test the MFT
                                            system’s response under various conditions. Identify potential issues before they impact actual
                                            users.
                                        </li>
                                        <li>
                                            User Experience Insights: Gain insights into the end-user experience by tracking synthetic user
                                            transactions, helping to optimize the system for better usability and performance.
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div id="issue-resolution">
                                <h2>Proactive Issue Resolution</h2>
                                <p>
                                    <ul>
                                        <li>
                                            Anomaly Detection: Utilize advanced observability tools to detect anomalies in file transfer
                                            processes, allowing for early intervention before issues escalate.
                                        </li>
                                        <li>
                                            Root Cause Analysis: Perform deep dives into performance data to identify the root causes of
                                            recurring issues and implement long-term fixes.
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div id="operational-efficiency">
                                <h2>Improving Operational Efficiency</h2>
                                <p>
                                    <ul>
                                        <li>
                                            Automated Workflows: Automate routine monitoring and reporting tasks, freeing up IT staff to focus on more strategic initiatives.
                                        </li>
                                        <li>
                                            Centralized Management: Provide a unified dashboard to manage and monitor all MFT instances across on-premises and cloud environments from a single interface.
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div id="vendor-monitoring">
                                <h2>Vendor Service Level Monitoring</h2>
                                <p>Monitor and validate the performance of MFT products and Cloud Services.</p>
                            </div>

                            <div id="partner-collaboration">
                                <h2>Business Partner Collaboration</h2>
                                <p>Enhance collaboration with business partners by providing them with performance data and insights, fostering a more transparent and productive relationship.</p>
                            </div>
                        </div>
                        <div>
                            <div className={classes.sectionsOverview}>
                                <h2>Overview</h2>
                                <ul>
                                    {sections.map(section => (
                                        <li key={section.id}>
                                            <a href={`#${section.id}`}>{section.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UseCases;
