import React from 'react';
import './Features.css';
import Card from './Card';
import placeholder from '../Images/placholder.png';
import placeholder2 from '../Images/placholder2.png';
import placeholder3 from '../Images/placholder3.png';
import placeholder4 from '../Images/placholder4.png';
import placeholder5 from '../Images/placholder5.png';
import placeholder6 from '../Images/placholder6.png';

const Features = () => {
  return (
    <div className="container">
      <section className="features" id="features">
        <h1>Full-suite Analytics</h1>
        <p>Discover Threpoly's extensive analytics suite,<br/> and realize your system's potential.</p>
        <div className="cardGrid">
          <article>
            <Card title="Snapshot Reporting" img={placeholder} imgAlt="Snapshot reporting">
              <p>Generate and analyse snapshot reports with up to 12 hours of data.</p>
            </Card>
          </article>

          <article>
            <Card title="Long-term Trends" img={placeholder2} imgAlt="Long-term trends">
              <p>Monitor long term changes in performance and system health.</p>
            </Card>
          </article>

          <article>
            <Card title="Keyframe Markers" img={placeholder3} imgAlt="Keyframe markers">
              <p>Mark system changes, and generate relevant frame reports.</p>
            </Card>
          </article>

          <article>
            <Card title="Pattern Detection" img={placeholder4} imgAlt="Pattern detection">
              <p>Machine learning is utilised to detect patterns, and plot user experience.</p>
            </Card>
          </article>

          <article>
            <Card title="Regional Division" img={placeholder5} imgAlt="Regional division">
              <p>Test and analyse using sources from around the globe.</p>
            </Card>
          </article>

          <article>
            <Card title="Deep Data Analysis" img={placeholder6} imgAlt="Deep data analysis">
              <p>Dig down to individual data points to identify bottlenecks.</p>
            </Card>
          </article>
        </div>
      </section>
    </div>
  );
};

export default Features;
