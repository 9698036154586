import React from 'react';
import './Card.css';
import placeholder from '../Images/placholder.png'

const Card = ({ title, children, img, imgAlt }) => {
  return (
    <div>
    <img src={img} className='placeholder' alt={imgAlt}/>
    <div className="card">

      <h2 className="card-title">{title}</h2>
      <div className="card-body">
        {children}
      </div>
    </div>
    </div>
  );
};

export default Card;
