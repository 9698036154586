import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Features from './Components/Features';
import About from './Components/About';
import Footer from './Components/Footer';
import HeaderBlog from './Components/HeaderBlog';
import ArticleOverview from "./Articles/ArticleOverview";
import Filepart from './Articles/Filepart';
import UseCases from "./Articles/UseCases";
import './App.css';
import { Analytics } from "@vercel/analytics/react";
import ReturnOnInvestment from "./Articles/ReturnOnInvestment";
import ScrollToTop from '../src/Components/ScrollToTop';
import BenefitsForChannelPartners from "./Articles/BenefitsForChannelPartners";
import FileTransferProtocolsEvolution from "./Articles/FileTransferProtocolsEvolution";

function Home() {
    return (
        <>
            <Header />
            <Hero />
            <Features />
            <About />
        </>
    );
}

function ArticlePage() {
    return (
        <>
            <HeaderBlog />
            <ArticleOverview />
        </>
    );
}

function App() {
    return (
        <Router>
            <ScrollToTop>
                <div className="App">
                    <Analytics />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/articles" element={<ArticlePage />} />
                        <Route path="/articles/filepart" element={<Filepart />} />
                        <Route path="/documentation/use-cases" element={<UseCases />} />
                        <Route path="/documentation/return-on-investment" element={<ReturnOnInvestment />} />
                        <Route path="/documentation/benefits-for-channel-partners" element={<BenefitsForChannelPartners />} />
                        <Route path="/articles/why-sftp" element={<FileTransferProtocolsEvolution />} />
                        {/* Add routes for additional articles here */}
                    </Routes>
                    <Footer />
                </div>
            </ScrollToTop>
        </Router>
    );
}

export default App;
