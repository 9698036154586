// Header.js
import React, { useState } from 'react';
import './Header.css';
import logo from '../threpoly-logo.svg';
import MobileNav from './MobileNav';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="container">
        <a href="/"><img src={logo} className="logo" alt="Threpoly Logo" /></a>
        <button className="burger-menu" onClick={toggleMenu}>
          ☰
        </button>
        <nav>
          <a href="#contact"><button className='free-demo'>Book demo</button></a>
          <a href="#features">Features</a>
          <a href="/articles">Documentation</a>
          <a href="#contact">Contact</a>
        </nav>
      </div>
      {menuOpen && <MobileNav toggleMenu={toggleMenu} />}
    </header>
  );
};

export default Header;
