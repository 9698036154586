import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './Articles.module.css';
import HeaderBlog from "../Components/HeaderBlog";

const BenefitsForChannelPartners = () => {
    const sections = [
        { id: 'reselling-threpoly', title: 'Reselling Threpoly Cloud Service' },
        { id: 'unlock-revenue', title: 'Unlock New Revenue Streams' },
        { id: 'enhance-satisfaction', title: 'Enhance Customer Satisfaction and Retention' },
        { id: 'drive-renewals', title: 'Drive Maintenance and Subscription Renewals' },
        { id: 'trusted-advisor', title: 'Position as a Trusted Advisor' },
        { id: 'enterprise-opportunities', title: 'Access to Enterprise Opportunities' },
        { id: 'marketing-support', title: 'Marketing and Support Collaboration' },
        { id: 'competitive-differentiation', title: 'Competitive Differentiation' },
        { id: 'conclusion', title: 'Conclusion' },
    ];

    useEffect(() => {
        const handleScroll = (event) => {
            event.preventDefault();
            const id = event.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(id);
            const offset = window.innerHeight / 2 - targetElement.getBoundingClientRect().height / 2;
            window.scrollTo({
                top: targetElement.offsetTop - offset,
                behavior: 'smooth'
            });
        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => link.addEventListener('click', handleScroll));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleScroll));
        };
    }, []);

    return (
        <>
            <HeaderBlog />
            <div className={classes.heroBlogWrapper}>
                <div className={classes.heroBlog}>
                    <div className={classes.heroInner}>
                        <h4>Documentation</h4>
                        <h1>Benefits for Channel Partners</h1>
                        <Link to='/articles'><p className={classes.backLink}>Back to documentation</p></Link>
                    </div>
                </div>
                <div className='container'>
                    <div className={classes.articleWrapper}>
                        <div className={classes.articleInner}>
                            <div id="reselling-threpoly">
                                <h2>Reselling Threpoly Cloud Service</h2>
                                <p>
                                    Managed File Transfer (MFT) solutions are crucial for securely exchanging data across networks, especially in industries with stringent data security and compliance requirements. To ensure optimal performance, security, and reliability, monitoring is essential.
                                    <br /><br />
                                    Threpoly is the only SaaS solution tailored specifically for MFT systems, providing advanced monitoring from sources around the globe, essential to ensure optimal performance, security, and reliability.
                                </p>
                            </div>

                            <div id="unlock-revenue">
                                <h2>Unlock New Revenue Streams</h2>
                                <p>
                                    While Threpoly’s subscription plans are offered at affordable prices, they present an opportunity for resellers to tap into a recurring revenue model. By securing a steady flow of subscriptions, even small revenues can accumulate significantly over time, especially with a growing customer base.
                                </p>
                            </div>

                            <div id="enhance-satisfaction">
                                <h2>Enhance Customer Satisfaction and Retention</h2>
                                <p>
                                    Threpoly provides unparalleled observability, performance monitoring, and synthetic user monitoring for Managed File Transfer solutions. By offering this service, resellers can enhance the reliability and efficiency of their customers' MFT systems, leading to higher satisfaction levels. Satisfied customers are more likely to remain loyal, resulting in increased customer retention rates.
                                </p>
                            </div>

                            <div id="drive-renewals">
                                <h2>Drive Maintenance and Subscription Renewals</h2>
                                <p>
                                    The advanced capabilities of Threpoly help customers maintain optimal performance and proactively address issues in their MFT systems. This proactive management translates into fewer disruptions and a smoother operational experience. Customers who experience these benefits are more likely to renew their subscriptions, driving consistent renewal rates and ongoing revenue for resellers.
                                </p>
                            </div>

                            <div id="trusted-advisor">
                                <h2>Position as a Trusted Advisor</h2>
                                <p>
                                    By reselling Threpoly, partners position themselves as experts in cutting-edge MFT monitoring solutions. This unique offering enhances the reseller’s portfolio, making them a more attractive and trusted advisor to their clients. This positioning can lead to stronger relationships and open doors to cross-selling and upselling other services.
                                </p>
                            </div>

                            <div id="enterprise-opportunities">
                                <h2>Access to Enterprise Opportunities</h2>
                                <p>
                                    For partners with larger customers, the top tier of Threpoly offers custom, high-value solutions tailored to specific needs. Selling these high-ticket subscriptions can significantly boost revenue. Moreover, successfully implementing Threpoly for a large enterprise can lead to long-term partnerships and potential referrals.
                                </p>
                            </div>

                            <div id="marketing-support">
                                <h2>Marketing and Support Collaboration</h2>
                                <p>
                                    Threpoly is committed to the success of its channel partners. Resellers will benefit from dedicated marketing resources, and ongoing support from Threpoly. This collaboration ensures that partners are well-equipped to promote and support Threpoly, further enhancing their credibility and effectiveness in the market.
                                </p>
                            </div>

                            <div id="competitive-differentiation">
                                <h2>Competitive Differentiation</h2>
                                <p>
                                    Threpoly is a unique offering with no direct competitors in the market. By including it in their product lineup, resellers can differentiate themselves from competitors. This unique value proposition can attract new customers looking for advanced MFT monitoring solutions that only Threpoly can provide.
                                </p>
                            </div>

                            <div id="conclusion">
                                <h2>Conclusion</h2>
                                <p>
                                    In summary, while the initial monetary gains from reselling Threpoly might be modest for some partners, the broader benefits of enhanced customer satisfaction, increased renewal rates, access to enterprise-level opportunities, and a strengthened market position make it a highly attractive addition to any reseller’s portfolio. Partnering with Threpoly is a strategic move that promises long-term growth and sustained success.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={classes.sectionsOverview}>
                                <h2>Overview</h2>
                                <ul>
                                    {sections.map(section => (
                                        <li key={section.id}>
                                            <a href={`#${section.id}`}>{section.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BenefitsForChannelPartners;
