import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './Articles.module.css';
import HeaderBlog from "../Components/HeaderBlog";

const ReturnOnInvestment = () => {
    const sections = [
        { id: 'roi-threpoly', title: 'ROI with Threpoly: Understanding the benefits' },
        { id: 'minimizing-outages', title: 'Minimizing or Avoiding Outages' },
        { id: 'reducing-support', title: 'Reducing the need for Support' },
        { id: 'reducing-time-to-fix', title: 'Reducing Time to Fix' },
        { id: 'guaranteeing-slas', title: 'Guaranteeing and Managing SLAs' },
        { id: 'reducing-downtime', title: 'Reducing Downtime' },
        { id: 'reputation', title: 'Reputation' },
        { id: 'roi', title: 'Return on Investment' },
        { id: 'conclusion', title: 'Conclusion' },
    ];

    useEffect(() => {
        const handleScroll = (event) => {
            event.preventDefault();
            const id = event.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(id);
            const offset = window.innerHeight / 2 - targetElement.getBoundingClientRect().height / 2;
            window.scrollTo({
                top: targetElement.offsetTop - offset,
                behavior: 'smooth'
            });
        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => link.addEventListener('click', handleScroll));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleScroll));
        };
    }, []);

    return (
        <>
            <HeaderBlog />
            <div className={classes.heroBlogWrapper}>
                <div className={classes.heroBlog}>
                    <div className={classes.heroInner}>
                        <h4>Documentation</h4>
                        <h1>ROI with Threpoly: Understanding the benefits</h1>
                        <Link to='/articles'><p className={classes.backLink}>Back to documentation</p></Link>
                    </div>
                </div>
                <div className='container'>
                    <div className={classes.articleWrapper}>
                        <div className={classes.articleInner}>
                            <div id="roi-threpoly">
                                <h2>ROI with Threpoly: Understanding the benefits</h2>
                                <p>
                                    Managed File Transfer (MFT) solutions are crucial for securely exchanging data across networks, especially in industries with stringent data security and compliance requirements. To ensure optimal performance, security, and reliability, advanced observability, performance monitoring, and synthetic user monitoring are essential.
                                    <br /><br />
                                    This document outlines the ROI provided by Threpoly, the only SaaS solution tailored specifically for MFT systems.
                                </p>
                            </div>

                            <div id="minimizing-outages">
                                <h2>Minimizing or Avoiding Outages</h2>
                                <p>
                                    Threpoly is able to identify potential issues with your environment before they become a reality. Climbing heartbeat timings, high variability, and deviation from baseline metrics all signal issues with service.
                                    <br /><br />
                                    Creating alerts based on performance thresholds keeps you aware of problems. Users are then able to investigate and take action before lasting, widespread outages affect real customers. Proactive monitoring helps stem the tide of support calls, reports of outages from the customer directly and eliminate the need for network-wide apologies.
                                    <br /><br />
                                    Threpoly is also able to validate that changes made to a lower environment do not negatively impact your users through outages or slowdowns and are safe to deploy to production.
                                    <br /><br />
                                    Best of all, keeping the file transfer platform working and stable allows the whole organization to be more productive. Critical files and services can arrive when they are needed, and not delayed by service drops.
                                </p>
                            </div>

                            <div id="reducing-support">
                                <h2>Reducing the need for Support</h2>
                                <p>
                                    Communicating with end users in order to keep them online and productive is critical. Staff hotlines, email inquiry, or problem report systems…? Obviously, when end users call in problems, the entire team becomes focused on problem fixes. In the worst cases, additional staff has to be added.
                                    <br /><br />
                                    When a file transfer service is misbehaving, and Threpoly is showing abnormal metrics, alerts are sent to technicians, and action can be taken before end users suffer from worsening conditions, and subsequently support calls are avoided. Working proactively in this way, support teams need not be overwhelmed by surprise service issues that are only responded to reactively.
                                    <br /><br />
                                    In full outages, frustrated end-users want answers, and keep support teams busy by requesting regular updates. Threpoly removes the guesswork when it comes to isolating an issue, and allows support teams to provide concrete answers immediately. Scenarios where the issue is external to a service are highlighted by Threpoly, as it monitors services over the internet from multiple sources globally.
                                    <br /><br />
                                    If Threpoly reduces calls per week to an average of 20 from an average of 50, and it takes, on average, 30 minutes to resolve or close a call, then time savings would be 15 hours. If an average, fully loaded hourly salary rate for network support staff is $30, then the per week savings would be $450, or $22,500 per year.
                                </p>
                            </div>

                            <div id="reducing-time-to-fix">
                                <h2>Reducing Time to Fix</h2>
                                <p>
                                    When technicians are notified of a problem, it can be difficult to understand who is affected and how.  Threpoly clearly shows which regions are impacted and any ongoing outages. Threpoly’s reporting tool can also clearly indicate not just when the outage occurred but when performance started to be impacted. Allowing technicians to pinpoint a time to investigate and understand any changes that could have been made.
                                    <br /><br />
                                    Looking for problem sources can take time, especially when relying on internal application tests that are reporting no issue at all. This is especially the case in siloed companies where changes can be made outside of your file transfer application but have direct impact. This could be anything from a database update to a configuration change on the load balancer.  Threpoly shows real-world, real-user experience, and through detailed breakdowns, it can isolate issues. Comparing current data against collected historical data, technicians can quickly understand the nature of an issue, and how it has arisen.
                                    <br /><br />
                                    After an issue has been identified, and a fix has been implemented, Threpoly can instantly give feedback that the fix can be verified around the world. This removes the need for technicians to remain on standby to confirm that an issue is not ongoing, and end users are not relied upon for reporting.
                                    <br /><br />
                                    The time it takes to find and fix problems, including the delay to normal duties, are easy-to-quantify costs. Threpoly provides live heartbeat data, with comprehensive breakdowns, regional isolation paired with historical data, and immediate fix confirmation, that can save hours, if not days, and drastically reduce technician overtime.
                                </p>
                            </div>

                            <div id="guaranteeing-slas">
                                <h2>Guaranteeing and Managing SLAs</h2>
                                <p>
                                    Whether operating as an internal unit or providing services to external clients, MFT teams are usually held to service level agreements (SLAs) set at 99.99% uptime. Meet the SLA, and end users or clients are happy. Fail to meet the SLA, and people start asking hard questions.
                                    <br /><br />
                                    In the same way that Threpoly can prevent an excess of support calls, it can go a long way towards SLA guarantees. MFT teams can proactively address potential disruptions by receiving early warnings on:
                                    <ul>
                                        <li>Slower than usual transfer times (based on comparative baselines)</li>
                                        <li>Unusually high service variability (service times intermittently spike)</li>
                                        <li>The immediate and long term effect of environment changes (patches and config)</li>
                                    </ul>
                                    In the case where MFT are generally critical to the organization and SLAs reflect true requirements, consistently met SLAs indicate that network Threpoly delivers ROI.
                                </p>
                            </div>

                            <div id="reducing-downtime">
                                <h2>Reducing Downtime</h2>
                                <p>
                                    There’s no doubt that MFT outages or slowdowns reduce employee productivity and commercial activity, but it can be hard to assign a monetary value to downtime. By identifying and addressing issues before they escalate, organizations can significantly reduce downtime. For instance, if an MFT system handles critical data transfers for a financial institution, even a few minutes of downtime can result in substantial financial losses and reputational damage.
                                    <br /><br />
                                    Delays in the receipt of critical files and information have a quantifiable impact on revenue, especially when considered as loss of opportunity. When delays are compounded over time, the delay of critical information can be thought of as a delay in action, and as such, slowing the whole company down.
                                    <br /><br />
                                    For example, if Threpoly eliminates 2 hours of unplanned or planned downtime per month, and the critical information that is delayed in turn delays revenue of just $500 per hour, then the ROI is $12,000 a year, just from downtime.
                                </p>
                            </div>

                            <div id="reputation">
                                <h2>Reputation</h2>
                                <p>
                                    MFT is a critical service and is usually the last step of the business on-boarding process, and as such has a direct impact on the relationship with end users and customers. Poor experience can lead to frustration, and or lead a customer to seek an alternative to the service provided.
                                    <br /><br />
                                    As previously mentioned, Threpoly can help reduce outage incidence, reduce downtime for maintenance, and help guarantee SLA. Additionally, through reporting of historical data, Threpoly can detect whether a service is improving or degrading over time, and give insight to whether a service requires expansion to meet demand.
                                    <br /><br />
                                    Loss of revenue to customers changing providers is difficult to calculate. Not least because it is difficult to isolate the cause of MFT experience alone. MFT is a critical service, however, and the felt impact of poor performance or management should not be underestimated.
                                    <br /><br />
                                    Even more difficult to calculate, is the effect on reputation, and the knock-on effect to onboarding future customers. It is easy to see that providing consistent and excellent service is of utmost importance to provider and client alike.
                                    <br /><br />
                                    Threpoly helps ensure excellent service experience, and in turn maintains relationships with clients both current and future.
                                </p>
                            </div>

                            <div id="roi">
                                <h2>Return on Investment</h2>
                                <p>
                                    How soon you will see ROI with Threpoly is dependent on how quickly you can start monitoring, and making improvements to your service. Several factors make Threpoly an effortless implementation, and therefore enable it to make quick ROI:
                                    <ul>
                                        <li>Standard On-boarding Procedure: Threpoly simulates an external user, so the steps to set-up and configure your environment to work with Threpoly should be standard and can done by anyone in the team</li>
                                        <li>Immediate availability: Threpoly is already running from sources globally, and is ready to begin monitoring your service over the internet.</li>
                                        <li>Ease of Use: Threpoly is easy to set up, and easy to use. Configuring the testing, notification and reporting parameters is quick and simple. Meaning monitoring can begin immediately.</li>
                                        <li>Security: Threpoly accesses your service over the internet securely, without handling sensitive information. This reduces delays in set-up, and allows for rapid deployment.</li>
                                    </ul>
                                </p>
                            </div>

                            <div id="conclusion">
                                <h2>Conclusion</h2>
                                <p>
                                    Threpoly's unique advanced observability, performance monitoring, and synthetic user monitoring for MFT solutions offer substantial ROI by enhancing performance, reliability, and user experience while reducing downtime and operational costs. By proactively managing potential issues, organizations can maintain seamless and secure data exchange, crucial for their operational success, and provide their users and business partners with consistent performance and reliability, enhancing their trust and satisfaction.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={classes.sectionsOverview}>
                                <h2>Overview</h2>
                                <ul>
                                    {sections.map(section => (
                                        <li key={section.id}>
                                            <a href={`#${section.id}`}>{section.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReturnOnInvestment;
