import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Footer.css';

const Footer = () => {
  const [honeypot, setHoneypot] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (honeypot) {
      console.log('Bot detected');
      return; // Pretend to submit the form
    }

    emailjs.sendForm('service_ckqip6f', 'template_diaq14e', e.target, 'elO_9EEwugoFV73pD')
      .then((result) => {
        console.log(result.text);
        setFormSubmitted(true); // Set formSubmitted to true on success
      }, (error) => {
        console.log(error.text);
        alert('Failed to send the message, please try again.');
      });

    e.target.reset();
  };

  return (
    <footer id="contact" className="footer">
      <div className="footer-grid">
        <div className="form-text-area">
          <h1>Request a demo</h1>
          <p>Or contact our team regarding sales and integration possibilities.
          <br/>Every passing minute is an opportunity for monitoring missed.</p>
        </div>
        <div className="footer-container">
          {formSubmitted ? (
            <p>Your enquiry has been successful. Our team will be in contact with more information shortly.</p>
          ) : (
            <form className="contact-form" onSubmit={sendEmail}>
              <div className="form-group">
                <input type="text" id="name" name="name" placeholder="Full name" required />
              </div>
              <div className="form-group">
                <input type="text" id="company" name="company" placeholder="Company name" required />
              </div>
              <div className="form-group">
                <input type="email" id="email" name="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <input type="tel" id="phone" name="phone" placeholder="Phone number" required />
              </div>
              <div className="form-group">
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>
              </div>
              {/* Honeypot field */}
              <div style={{ display: 'none' }}>
                <input
                  type="text"
                  id="honeypot"
                  name="honeypot"
                  value={honeypot}
                  onChange={(e) => setHoneypot(e.target.value)}
                  placeholder="Leave this field blank"
                />
              </div>
              <button type="submit" className="submit-button">Send</button>
            </form>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
